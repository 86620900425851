<template>
      <div class="inventoryLabResultCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'inventoryLabResult', params: {} }">
          Inventory Lab Result
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'inventoryLabResultCreate', params: {} }">
            Create Inventory Lab Result
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Inventory Lab Result</v-card-title>
          <v-card-text>
            <inventoryLabResult-form></inventoryLabResult-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import InventoryLabResultForm from './InventoryLabResultForm.vue';

    export default {
      name: 'inventoryLabResult',
      components: {
        InventoryLabResultForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    